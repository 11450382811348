import React from "react";

// import concept from '../pug/_object/_project/_top/_t-top__concept.pug';

class T_about_questions extends React.Component {
    render() {
        const cartUrl = process.env.CART_SITE_URL;
        return (
          <section className="t-about__questions" id="questions">
              <img className="t-about__bgImg1" src={"/images/about/color1.png"} alt="" />
              <div className="c-grid">
                <div className="c-grid__row c-spacer--pb120 c-spacer--pb70-sp">
                  <div className="c-grid__col js-smScrollFire js-smScrollFire--fadeUp is-active" data-hook="0.9">
                    <a className="p-button p-button--circle-right p-button--tall" href={cartUrl + "/hotaru/questions?openExternalBrowser=1"}>
                      <span className="p-button__label p-button__label--camel-font-size">
                        <span className="p-button__label--large u-block-sp c-spacer--pt5-sp">ONLINE無料診断</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
          </section>
        )
    }
}
export default T_about_questions