import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_ABOUT_QUESTIONS from "../../components/t_about_questions"

const MatsumotoMarikaInterviewPage = () => (
    <Layout sectionClasses={"t-matsumoto-marika-interview"} dataNamespace={"matsumoto-marika-interview"}>
      <SEO title="松本 まりか インタビュー" />

      <div className="t-matsumoto-marika-interview__mv">
        <h2 className={"t-matsumoto-marika-interview__mv__ttl"}>Special Contents</h2>
        <img className={"t-matsumoto-marika-interview__mv__img"} src={"/images/specialcontents/mminterview/interview_11.jpg?v=20210219"} alt="" />
        <p className={"t-matsumoto-marika-interview__mv__desc"}>
          HOTARU PERSONALIZED（以下、HOTARU）は、
          このたび女優・松本まりかさんをブランドイメージキャラクターに起用させていただきました。<br />
          <br />
          HOTARUのコンセプトは、<br />
          “この肌は、私らしいか。私だけの輝きを放っているか。”<br />
          2020年にデビュー20周年を迎え、さらに活躍の幅を広げながら魅力を増し続ける松本まりかさん。
          その個性的でありながらピュアな魅力は、まさしくブランドのコンセプトを体現するもの。
          この記事では、松本さんが感じたパーソナライズスキンケアの魅力と、その美肌の秘訣についてインタビューしました。
        </p>
      </div>

      <div className="t-matsumoto-marika-interview__iv">
        <h2 className="t-matsumoto-marika-interview__iv__ttl__top">Special Interview<br /><span>松本 まりか　インタビュー</span></h2>
        <img className="t-matsumoto-marika-interview__iv__img" src={"/images/specialcontents/mminterview/interview_21.jpg?v=20210219"} alt="" />
        <p className="t-matsumoto-marika-interview__iv__ttl">占いみたいに気軽。<br />だけど自分の肌に必要なことが分かる。</p>
        <hr />
        <p className="t-matsumoto-marika-interview__iv__q">スマートフォンによる10の質問とカメラ診断で分かるHOTARUの肌診断。体験してみた第一印象は？</p>
        <p className="t-matsumoto-marika-interview__iv__a">
          まるで占い感覚で、気軽に自分の肌が知れるのが楽しかったです。質問に答えていくごとに、肌の悩みが明確になっていくのがいくんですよね。
          「私はこういう肌です」って、自分ではうまく言葉にできないけれど、HOTARUの肌診断を通じて言語化してもらえるので、モヤモヤが解決するような感覚があります。<br />
          これは初めての体験ですね。お肌の診断って、デパートに行ったときに何年かに１度お試しで受けてみたことしかありませんでした。高級でなかなか手を出せないイメージも…。<br />
          スマホで出来るなら自分一人で誰にも見られずにできますよね。自分の悩みが明確に分かるから、すごくドキッとしますけど、自分の肌にとっては知らなきゃいけないことなので、勉強になります。
        </p>
        <p className="t-matsumoto-marika-interview__iv__q">実際の化粧水と美容乳液の使い心地はいかがですか？</p>
        <p className="t-matsumoto-marika-interview__iv__a">
          心地いいですね。テクスチャーも選べるから私好みのものが届いたなと思います。
          香りもリッチですね。香りって自分を癒すことが出来るし重要だと思うんですが、まるでお花畑にいるような 気持ちになれる香りですね。
        </p>
        <img className="t-matsumoto-marika-interview__iv__img" src={"/images/specialcontents/mminterview/interview_3.jpg?v=20210219"} alt="" />
        <p className="t-matsumoto-marika-interview__iv__ttl">
          スキンケアで大切にしていることは<br />
          “シンプルさ”。<br />
          必要な時に必要な成分だけ入れたい。
        </p>
        <hr />
        <p className="t-matsumoto-marika-interview__iv__q">松本さんのこれまでのスキンケア選びのこだわりは？</p>
        <p className="t-matsumoto-marika-interview__iv__a">
          中身で言うと、結構シビアにオーガニック成分を 選んできました。
          今回HOTARUのスキンケアセットは ほぼ自然由来のものでできていると聞いて、
          本当にこだわって作っていることに安心できたので、 使いやすかったです。<br />
          <br />
          もう一つは、シンプルであること。<br />
          あまり肌を甘えさせたくないので、自分に必要ない成分まで過剰に使いたくないんです。
          必要な時に必要な成分だけ補いたいと思っています。
          自分だけのオーダーメイドなら、時期によっても違うスキンケアセットを作ることができるから、いつも自分に合ってるものができる。<br />
          それにステップとしてもシンプルで、化粧水と美容乳液の二本で完結するのが嬉しいですよね。
          お仕事で忙しい方やママさんにも受け入れやすいと思いますし、救世主のようなブランドだなと思います。
        </p>
        <p className="t-matsumoto-marika-interview__iv__q">なぜシンプルであることを大切にするようになったのでしょうか？</p>
        <p className="t-matsumoto-marika-interview__iv__a">
          昔は、闇雲にいいと思われる物を使っていたんです。自分に合っているものが分からなくて…。
          でも、年齢とともに感覚が洗練されていくうちに、シンプルであることの重要性が分かるようになりました。
          いろんな化粧品があるけれど、自分に必要なだけ必要なものを使うことが大切。<br />
          <br />
          早い内から自分に合ったスキンケアを知ることで、より良い肌に早く出会うことができるし、
          そんな自分にぴったりのスキンケアを見つけること自体、私にとっては楽しい作業です。
        </p>
        <img className="t-matsumoto-marika-interview__iv__img" src={"/images/specialcontents/mminterview/interview_41.jpg?v=20210219"} alt="" />
      </div>
      <div className="t-matsumoto-marika-interview__btm">
        <h2 className="t-matsumoto-marika-interview__btm__ttl">Special Interview<br /><span>松本 まりか　インタビュー</span></h2>
        <p className="t-matsumoto-marika-interview__btm__desc">
          シャッター音のたびに様々な表情を見せ、自分だけの輝きを放つ松本まりかさんの姿は、まさにHOTARUのコンセプトそのものを表現していました。<br />
          そんな松本さんの魅力が溢れた撮影現場の一部とインタビューの様子をYoutubeで公開中。
        </p>
        <img className="t-matsumoto-marika-interview__btm__img" src={"/images/specialcontents/mminterview/interview_51.jpg?v=20210219"} alt="" />
      </div>

      <T_ABOUT_QUESTIONS />
      <Link className="p-pageBackBtn" to="/">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col">
              <span className="p-pageBackBtn__inner">BACK TO TOP</span>
            </div>
          </div>
        </div>
      </Link>
    </Layout>
)

export default MatsumotoMarikaInterviewPage
